// Min width    
$min-small: 640px
$min-medium: 768px
$min-large: 1024px
$min-desktop: 1280px
$min-large-desktop: 1900px

// Max width    
$max-small: 639.98px
$max-medium: 767.98px
$max-large: 1023.98px
$max-desktop: 1279.98px
$max-large-desktop: 1899.98px

// Colors
$primary: #C69C6D
