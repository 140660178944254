@import 'src/assets/variables'

.vertical-line
    .vertical-line-square
        width: 6px
        height: 6px
        background-color: $primary
        transform: rotate(45deg)
    .vertical-line-actual-line
        width: 2px
        height: 170px
        margin: 4px 2px
        background-color: $primary
    .vertical-line-actual-line-small
        height: 95px
.vertical-line-margin
    margin: 0 40px
