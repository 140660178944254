@import 'src/assets/variables'

.rooms-container
    width: 100%
    display: flex
    padding: 10vh 0 0
    flex-direction: column
    align-items: center
    @media only screen and (max-width: 1250px) and (orientation: landscape)
        padding: 10vw 0 0
    .rooms-item
        width: 100%
        display: flex
        margin: 12px 0 48px
        flex-direction: column
        align-items: center
        .rooms-item-title
            line-height: 1
            font-size: 54px
            font-weight: 500
            margin: 24px 0 12px
            letter-spacing: 0.5px
            font-family: 'Vollkorn', serif
            @media only screen and (max-width: 400px)
                font-size: 36px
        .rooms-item-content
            width: 45%
            margin: 0 0 58px
            .rooms-item-content-item
                width: 100%
                display: flex
                flex-direction: row
                letter-spacing: 1px
                justify-content: stretch
                align-items: center
                .horizontal-line
                    margin: 0 6px
                    @media only screen and (max-width: $max-medium)
                        .horizontal-line-actual-line
                            width: 120px
                    @media only screen and (max-width: 360px)
                        .horizontal-line-actual-line
                            width: 60px
                div
                    span
                        color: $primary
                    @media only screen and (max-width: 400px)
                        font-size: 14px
                .right-text
                    flex: 1 0 0
                    text-align: right
                .left-text
                    flex: 1 0 0
                    text-align: left
            @media only screen and (max-width: 1600px)
                width: 60%
            @media only screen and (max-width: $max-large)
                width: 80%
            @media only screen and (max-width: $max-medium)
                width: 90%
            @media only screen and (max-width: 400px)
                width: 95%
        .rooms-item-note
            margin: 18px 0
            font-size: 14px
            color: #000000
            letter-spacing: 1px
            @media only screen and (max-width: 400px)
                width: 90%
