@import 'src/assets/variables'

.rule
    display: flex
    width: 40%
    margin: 18px 0 8px
    flex-direction: column
    align-items: center
    @media only screen and (max-width: 1500px)
        width: 50%
    @media only screen and (max-width: $max-large)
        width: 60%
    @media only screen and (max-width: $max-medium)
        width: 90%
    .rule-title
        color: $primary
        font-size: 36px
        margin: 12px 0 22px
        font-weight: 500
        font-family: 'Vollkorn', serif
    .rule-points
        width: 100%
        text-align: left
        letter-spacing: 1px
    .rule-bullets
        margin: 0 0 0 54px
        @media only screen and (max-width: $max-medium)
            margin: 0 0 0 24px
