@import 'src/assets/variables'

.horizontal-line
    display: flex
    flex-direction: row
    .horizontal-line-square
        width: 6px
        height: 6px
        background-color: $primary
        transform: rotate(45deg)
    .horizontal-line-actual-line
        height: 2px
        width: 170px
        margin: 2px 4px
        background-color: $primary
    .horizontal-line-actual-line-small
        width: 95px

.horizontal-line-margin
    margin: 0 40px

.horizontal-line-no-margin
    .horizontal-line-actual-line
        margin: 0
