@import 'src/assets/variables'

.contact-container
    width: 100%
    display: flex
    padding: 12vh 0 0
    flex-direction: column
    align-items: center
    @media only screen and (max-width: 1250px) and (orientation: landscape)
        padding: 10vw 0 0
    .contact-title
        font-size: 54px
        font-weight: 500
        margin: 42px 4px 0
        letter-spacing: .5px
        line-height: 1.1
        text-align: center
        font-family: 'Vollkorn', serif
    .contact-items
        width: 40%
        margin: 48px 0 64px
        display: flex
        flex-direction: row
        justify-content: space-between
        .contact-item
            letter-spacing: 1px
            .title
                color: $primary
                font-size: 12px
                font-weight: 600
                text-transform: uppercase
            .text
                font-size: 21px
        @media only screen and (max-width: 1600px)
            width: 40%
        @media only screen and (max-width: $max-desktop)
            width: 50%
        @media only screen and (max-width: $max-large)
            width: 60%
        @media only screen and (max-width: $max-medium)
            flex-direction: column
            align-items: center
            width: 100%
