@import 'src/assets/variables'

.attractions-container
    width: 100%
    display: flex
    padding: 12vh 0 0
    flex-direction: column
    align-items: center
    @media only screen and (max-width: 1250px) and (orientation: landscape)
        padding: 10vw 0 0
    .attractions-title
        font-size: 54px
        font-weight: 500
        margin: 42px 4px 0
        letter-spacing: .5px
        line-height: 1.1
        text-align: center
        font-family: 'Vollkorn', serif
        span
            color: $primary
        @media only screen and (max-width: $max-medium)
            font-size: 42px
    .attractions-items
        display: flex
        flex-direction: column
        width: 45%
        margin: 36px 0 58px
        @media only screen and (max-width: 1600px)
            width: 60%
        @media only screen and (max-width: $max-large)
            width: 80%
        @media only screen and (max-width: $max-medium)
            width: 90%
        @media only screen and (max-width: 400px)
            width: 95%
        .attractions-item
            width: 100%
            display: flex
            flex-direction: row
            letter-spacing: 1px
            justify-content: space-between
            align-items: center
            margin: 0 0 16px
            .horizontal-line
                margin: 0 6px
                @media only screen and (max-width: $max-medium)
                    .horizontal-line-actual-line
                        width: 80px
            .right-text
                flex: 1 0 0
                text-align: right
                color: $primary
                span
                    margin: 0 4px
                    color: #000
                @media only screen and (max-width: $max-medium)
                    font-size: 12px
            .left-text
                flex: 1 0 0
                text-align: left
                @media only screen and (max-width: $max-medium)
                    font-size: 12px
    .attractions-icons
        width: 80%
        display: flex
        flex-wrap: wrap
        margin: 24px 0 84px
        flex-direction: column
        @media only screen and (max-width: $max-medium)
            margin: 16px 0 62px
        .attractions-icons-row
            display: flex
            flex-direction: row
            @media only screen and (max-width: $max-medium)
                flex-direction: column
        .attractions-icons-item
            display: flex
            margin: 32px 0 0
            flex-direction: row
            flex: 1 0 0
            justify-content: center
            align-items: center
            @media only screen and (max-width: $max-medium)
                justify-content: flex-start
            .icon
                width: auto
                height: 48px
                margin: 0 24px 0 0
            .icons-text
                letter-spacing: 1px
                .title
                    font-size: 18px
                    color: $primary
                    font-family: 'Vollkorn', serif
                .text
                    span
                        color: $primary
