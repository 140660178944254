@import 'src/assets/variables'

header
    z-index: 2
    color: #fff
    display: flex
    padding: 22px 13%
    align-items: center
    flex-direction: row
    justify-content: center
    @media only screen and (max-width: 1400px)
        padding: 20px 8%
    @media only screen and (max-width: $max-desktop)
        padding: 18px 5%
    @media only screen and (max-width: $max-large)
        padding: 12px 16px
    @media only screen and (max-width: $max-medium)
        padding: 10px 16px

.navbar
    width: 100vw
    z-index: 2
    position: absolute
    overflow: hidden
    overflow-y: auto

.navbar-active
    height: 100vh
    position: fixed
    display: flex
    overflow: hidden
    overflow-y: auto
    flex-direction: column
    transition: 0.5s background-color
    background-color: #222
    .dark-header
        color: #fff
        .menu-button
            div
                border-color: #fff
        .nav-location
            .nav-location-home
                color: #fff
            .nav-location-page
                span
                    border-color: #fff
        .nav-center
            .nav-center-top
                .nav-center-top-line
                    border-color: #fff
    .btn-dark
        color: #fff
        border: 2px solid #FFFFFF
        background: linear-gradient(to left, transparent 50%, #fff 50%) right
        background-size: 201%
    .btn-dark:hover
        color: rgba(0, 0, 0, 0.5)
        font-weight: 600
        letter-spacing: 1.9px
        background-position: left

.navbar-deactivate
    z-index: 1
    position: absolute
    transition: 0.5s background-color

.nav-left
    flex: 1
    margin-right: auto
    display: inline-flex
    @media only screen and (max-width: $max-large)
        font-size: 14px
    .menu-button
        display: flex
        flex-direction: column
        justify-content: center
        margin: 0px 32px 0 0
        @media only screen and (max-width: $max-large)
            margin: 3px 2px 0 0
        div
            display: block
            width: 24px
            border-bottom: 2px solid rgba(255,255,255, 1)
            transition: transform .2s ease, background-color .5s ease
        .menu-button-middle
            margin: 5px 0
            width: 19px
    .menu-button-active
        margin-top: 4px
        margin-left: -2px
        @media only screen and (max-width: $max-desktop)
            margin-right: 29px
            margin-left: 1px
        @media only screen and (max-width: 400px)
            margin-right: 2px
        div
            width: 26px
            border-width: 2px
        .menu-button-middle
            width: 26px
        .menu-button-left
            background-color: #000
            transform: translateX(0px) rotate(45deg)
        .menu-button-middle
            display: none
        .menu-button-right
            transform: translateY(-2px) rotate(-45deg)

    .nav-location
        display: flex
        flex-direction: row
        letter-spacing: 1px
        text-transform: uppercase
        .nav-location-home
            color: #fff
            line-height: 1.6
            text-decoration: none
            @media only screen and (max-width: $max-large)
                display: none
        .nav-location-page
            display: inline-flex
            margin: 0px 0 0 6px
            @media only screen and (max-width: $max-large)
                display: none
            span
                display: inline-block
                line-height: 1
                margin: 3px 0 8px 6px
                border-bottom: 1px solid #fff
        .nav-location-page-home
            display: inline-flex
            margin: 0px 0 0 6px
            @media only screen and (max-width: $max-large)
                display: none
            span
                margin: 0 0 0 6px
    .nav-location-margin-top
        margin: 4px 0 0

.nav-center
    display: flex
    align-items: center
    flex-direction: column
    text-transform: uppercase
    font-family: 'Vollkorn', serif
    .nav-center-top
        display: flex
        flex-direction: row
        align-items: center
        .nav-center-top-title
            margin: 1px 8px 0px
            font-size: 14px
            @media only screen and (max-width: $max-large)
                font-size: 14px
                margin: 1px 4px 0px
        .nav-center-top-line
            width: 36px
            border-bottom: 2px solid #fff
            @media only screen and (max-width: $max-large)
                width: 32px
                border-bottom: 1px solid #fff
    .nav-center-title
        line-height: 1
        font-size: 20px
        font-weight: 600
        letter-spacing: 1px
        @media only screen and (max-width: $max-large)
            font-size: 20px

.nav-right
    flex: 1
    display: flex
    margin-left: auto
    justify-content: flex-end
    .nav-right-page
        display: none
    @media only screen and (max-width: $max-large)
        .nav-right-page
            display: block
            line-height: 1.2
            text-transform: uppercase
            margin: 0 0 0 6px
            font-size: 17px
            border-bottom: 1px solid #fff
        .nav-right-page-dark
            border-bottom: 1px solid #000
        button
            display: none
    @media only screen and (max-width: 450px)
        .nav-right-page
            display: none

.dark-header
    color: #1e1e1e
    .menu-button
        div
            border-color: #000
    .nav-location
        .nav-location-home
            color: #1e1e1e
        .nav-location-page
            span
                border-color: #000
    .nav-center
        .nav-center-top
            .nav-center-top-line
                border-color: #000
