@import 'src/assets/variables'

.carousel
    width: 85%
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    .carousel-image
        animation: slideshow 10s linear infinite
    img
        width: 100%
        height: 100%
    .carousel-counter
        font-size: 14px
        margin: 6px 0 0
        color: $primary
        display: flex
        flex-direction: row
        align-items: center
        div
            width: 155px
            margin: 10px
            border-top: 1px solid $primary
            border-bottom: 1px solid $primary
            @media only screen and (max-width: 450px)
                width: 120px
