@import 'src/assets/variables'

.home
    height: 100vh
    background-repeat: no-repeat
    background-size: cover
    background-position: center

.home-landing
    width: 100%
    height: 100vh
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    font-family: 'Vollkorn', serif
    .dots
        visibility: hidden
        animation: 0.7s 0.5s 1 slideFromTop
        animation-fill-mode: forwards
    .home-landing-top
        display: flex
        line-height: 0.6
        align-items: center
        text-transform: uppercase
        animation: 0.6s 0s 1 slideFromTop
        span
            margin: 0 12px
            color: #fff
            font-size: 35px
            letter-spacing: 0.2em
            @media only screen and (max-width: $max-medium)
                margin: 0 6px
                font-size: 18px
        div
            width: 140px
            border-bottom: 2px solid #fff
            @media only screen and (max-width: $max-large)
                width: 40px
                border-bottom: 1px solid #fff
    .home-title
        margin: 15px 0 0
        line-height: 1
        font-size: 80px
        font-weight: 700
        color: #fff
        text-transform: uppercase
        text-align: center
        visibility: hidden
        animation: 0.7s 0.3s 1 slideFromTop
        animation-fill-mode: forwards
        @media only screen and (max-width: $max-medium)
            white-space: pre-wrap
            margin: 10px 0 0
            font-size: 52px
            line-height: 1.1
    .home-bottom
        margin: 2px 0 0

@keyframes slideFromTop
    0%
        transform: translate3d(0px, 75px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)
        transform-style: preserve-3d
        opacity: 0
    100%
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)
        transform-style: preserve-3d
        opacity: 1
        visibility: visible
