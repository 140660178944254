@import 'src/assets/variables'

.home-carousel
    display: flex
    min-height: 100vh
    padding: 0 0 32px
    align-items: center
    flex-direction: column
    background: linear-gradient(#EFEFEF 60%, #FFF 40%)
    @media only screen and (max-width: 1600px)
        min-height: 80vh
    @media only screen and (max-width: $max-desktop)
        min-height: 40vh
    @media only screen and (max-width: $max-medium)
        padding: 0 0 26px
        min-height: 30vh
    .home-carousel-line
        width: 432px
        align-self: flex-start
        margin: 14px 0 12px 7.5%
        border-bottom: 2px solid $primary
        @media only screen and (max-width: $max-desktop)
            width: 350px
        @media only screen and (max-width: $max-large)
            width: 300px
        @media only screen and (max-width: $max-medium)
            width: 170px
        @media only screen and (max-width: 350px)
            width: 120px
