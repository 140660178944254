.btn
    outline: none
    color: #fff
    display: inline-block
    background: none
    font-size: 11px
    min-width: 150px
    min-height: 38px
    background: none
    padding: 8px 24px
    letter-spacing: 2px
    background-size: 200%
    transition: .3s ease-out
    text-transform: uppercase
    border: 2px solid #FFFFFF
    font-family: 'Titillium Web', sans-serif
    background: linear-gradient(to left, transparent 50%, #fff 50%) right
    background-size: 201%

.btn:hover
    color: rgba(0, 0, 0, 0.5)
    font-weight: 600
    letter-spacing: 1.9px
    background-position: left

.btn-primary
    color: #C69C6D
    background-size: 200%
    border: 2px solid #C69C6D
    background: linear-gradient(to left, transparent 50%, #C69C6D 50%) right
    background-size: 201%

.btn-primary:hover
    color: #C69C6D
    background-color: #C69C6D
    background-position: left

.btn-primary-background
    color: #fff
    border: 2px solid #C69C6D
    background: linear-gradient(to left, #C69C6D 50%, transparent 50%) right
    background-size: 201%

.btn-primary-background:hover
    color: #C69C6D
    background-color: transparent
    background-position: left

.btn-dark
    color: #1e1e1e
    border: 2px solid #1e1e1e
    background: linear-gradient(to left, transparent 50%, #1e1e1e 50%) right
    background-size: 201%

.btn-dark:hover
    color: #fff
    font-weight: 600
    background-position: left
    background-color: #1e1e1e

.btn-primary-backgroundHover
    color: #fff
    border: 2px solid #C69C6D
    background: #C69C6D
    background-size: 201%

.btn-primary-backgroundHover:hover
    color: #fff
