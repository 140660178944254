.rules-container
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 56px 0
    .rules-title
        font-size: 54px
        font-weight: 500
        margin: 36px 0 0
        letter-spacing: .5px
        font-family: 'Vollkorn', serif
