@import 'src/assets/variables'

.nav-body-links
    display: flex
    flex-direction: column
    a
        width: fit-content
        line-height: 1
        color: #fff
        font-size: 54px
        margin: 40px 0 0
        font-weight: 600
        letter-spacing: 5px
        overflow: hidden
        text-decoration: none
        background: linear-gradient(to right, #c69c6d, #c69c6d 50%, #fff 50%)
        background-clip: text
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-size: 200% 100%
        background-position: 100%
        text-transform: uppercase
        font-family: "Vollkorn",serif
        transition: background-position 0.4s ease
        @media only screen and (max-width: $max-desktop)
            font-size: 42px
        @media only screen and (max-width: $max-large)
            font-size: 32px
            margin: 24px 0 0
        @media only screen and (max-width: 450px)
            font-size: 20px
            margin: 16px 0 0
    a:hover
        background-position: 0 100%
    .nav-body-links-small
        margin: 5px 0 0
        display: inline-flex
        a
            color: #C69C6D
            font-size: 16px
            line-height: 1
            margin: 0 12px 0 0
            background: linear-gradient(to right, #fff, #fff 50%,  #c69c6d 50%)
            background-clip: text
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-size: 200% 100%
            background-position: 100%
        @media only screen and (max-width: $max-large)
            margin: 3px 0 0
            a
                font-size: 14px
        @media only screen and (max-width: 450px)
            a
                font-size: 10px
        a:hover
            background-position: 0 100%
