@import 'src/assets/variables'

.info-container
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 56px 0
    .info-title
        font-size: 54px
        font-weight: 500
        margin: 42px 4px 0
        letter-spacing: .5px
        line-height: 1.1
        text-align: center
        font-family: 'Vollkorn', serif
        span
            color: $primary
            display: inline-block
            line-height: 1.1
            border-bottom: 2px solid $primary
    .info-points
        width: 40%
        letter-spacing: 1px
        margin: 36px 0 56px
        div
            margin: 2px 0
        @media only screen and (max-width: 1500px)
            width: 50%
        @media only screen and (max-width: $max-large)
            width: 60%
        @media only screen and (max-width: $max-medium)
            width: 90%
