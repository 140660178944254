@import 'src/assets/variables'

.home-offer
    display: flex
    flex-direction: column
    align-items: center
    .dots
        margin: 6px 0 46px
        @media only screen and (max-width: $max-medium)
            margin: 4px 0 36px
