@import 'src/assets/variables'

.nav-body
    color: #fff
    display: none
    @media only screen and (max-width: $max-large)
        height: auto

.nav-body-active
    z-index: 1
    width: 100vw
    height: 100vh
    position: fixed
    display: flex
    box-sizing: border-box
    flex-direction: row
    justify-content: space-between
    align-items: center
    overflow: hidden
    animation: showOpacity 0.5s normal forwards
    padding: 0 13%
    @media only screen and (max-width: 1400px)
        padding: 0 8%
    @media only screen and (max-width: $max-desktop)
        padding: 0 5%
    @media only screen and (max-width: $max-large)
        height: 100vh
        overflow-y: scroll
        flex-direction: column
        align-items: flex-start
        justify-content: center
        padding: 0 48px
    @media only screen and (max-width: 450px)
        justify-content: flex-start
        margin: 10vh 0 0
        padding: 0vh 16px 12px
    @media only screen and (max-width: $max-large) and (orientation: landscape)
        justify-content: flex-start
        margin: 20vh 0 0
        padding: 2vh 48px 12px

.nav-body-deactivate
    animation: showOpacity 0.5s normal forwards

@keyframes showOpacity
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes hideOpacity
    0%
        opacity: 1
    100%
        opacity: 0
