@import 'src/assets/variables'

.home-offer-rental
    width: 100%
    height: auto
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    .home-offer-rental-background
        width: 100%
        min-height: 130vh
        position: relative
        display: flex
        flex-direction: column
        align-items: center
        @media only screen and (max-width: 1700px)
            min-height: 120vh
        @media only screen and (max-width: 1500px)
            min-height: 110vh
        @media only screen and (max-width: 1400px)
            min-height: 125vh
        @media only screen and (width: 1366px) and (height: 1024px)
            min-height: 100vh
        @media only screen and (max-width: $max-desktop)
            min-height: auto
    .vertical-line
        margin: 32px 0 44px
        @media only screen and (max-width: $max-desktop)
            margin: 24px 0 32px
    .home-rental-title
        line-height: 1
        font-size: 54px
        font-weight: 500
        margin: 12px 0
        text-align: center
        font-family: 'Vollkorn', serif
        @media only screen and (max-width: $max-medium)
            font-size: 32px
        span
            display: inline-block
            line-height: 0.7
            color: $primary
            border-bottom: 2px solid $primary
    .home-rental-description
        top: 40%
        left: 15%
        width: 30%
        font-size: 14px
        position: absolute
        @media only screen and (min-width: 2000px)
            font-size: 16px
            top: 40%
        @media only screen and (max-width: 1300px)
            width: 40%
            left: 10%
            top: 50%
        @media only screen and (max-width: $max-desktop)
            position: relative
            width: 90%
            left: 0
            margin: 24px 0 12px
    .home-rental-bike
        position: absolute
        top: 8%
        right: 0
        width: 70%
        z-index: 1
        img
            width: 100%
        @media only screen and (min-width: 2400px)
            width: 40%
        @media only screen and (max-width: 1700px)
            top: 10%
        @media only screen and (max-width: 1500px)
            top: 16%
        @media only screen and (max-width: 1300px)
            width: 65%
            top: 25%
        @media only screen and (max-width: $max-desktop)
            width: 70%
            position: relative
            align-self: flex-end
        @media only screen and (max-width: $max-large)
            width: 100%
        @media only screen and (max-width: $max-desktop) and (orientation: landscape)
            width: 80%
            margin: 20px 0 0
    .home-offer-carousel
        display: flex
        justify-content: center
        width: 100%
        height: 50vw
        position: relative
        text-align: center
        @media only screen and (max-width: $max-large) and (orientation: landscape)
            height: 55vw
        @media only screen and (max-width: 400px)
            height: 55vw
    .carousel
        top: -10%
        z-index: 1
        position: absolute
        @media only screen and (max-width: $max-desktop)
            top: -15vh
        @media only screen and (max-width: 1024px)
            top: -10vh
        @media only screen and (max-width: $max-medium)
            top: -10vh
        @media only screen and (max-width: 450px)
            top: -12vh
        @media only screen and (max-width: 400px)
            top: -10vh
    .home-mobile-flex
        width: 100%
        @media only screen and (max-width: $max-desktop)
            display: flex
            flex-direction: column
            align-items: center
            width: 100%
            top: 0
    footer
        width: 100%
        background: rgb(239, 239, 239)
        .home-footer
            margin: 2px 15% 6px
            max-width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            .footer-design
                a
                    color: #000
                    font-weight: 600
                    text-decoration: none
            @media only screen and (max-width: $max-large)
                width: 95%
                margin: 0 2.5% 6px
            @media only screen and (max-width: 450px)
                flex-direction: column
                align-items: center
