@import 'src/assets/variables'

.home-buildings
    .home-buildings-title
        font-size: 54px
        font-weight: 500
        text-align: center
        font-family: 'Vollkorn', serif
        @media only screen and (max-width: $max-medium)
            font-size: 32px
    .home-buildings-list
        width: 100%
        display: flex
        align-items: center
        flex-direction: row
        .vertical-line-margin
            margin: 0 70px
        @media only screen and (max-width: 1600px)
            .vertical-line-margin
                margin: 0 45px
        @media only screen and (max-width: $max-desktop)
            flex-direction: column
            justify-content: center
            align-items: center
            .vertical-line
                transform: rotate(90deg)
                margin: -7vh 0
        @media only screen and (max-width: 400px)
            .vertical-line
                margin: -10vh 0
        @media only screen and (max-width: 400px)
            .vertical-line
                margin: -14vh 0
        .home-buildings-building
            margin: 26px 0 52px
            width: 600px
            height: auto
            text-align: center
            position: relative
            @media only screen and (max-width: 1300px)
                width: 500px
            @media only screen and (max-width: $max-desktop)
                width: 90%
                margin: 18px auto 36px
            img

                width: 100%
                height: 100%
            div
                position: absolute
                left: 50%
                z-index: 1
                bottom: 0
                margin: 0 0 -12.5px
            button
                position: relative
                left: -50%
