@import 'src/assets/variables'

.nav-body-details
    display: flex
    align-items: center
    button
        display: none
    @media only screen and (max-width: $max-large)
        margin: 47px 0 0
        flex-direction: column
        align-items: flex-start
        button
            display: block
            margin: 24px 0 0
    @media only screen and (max-width: 450px)
        margin: 26px 0 0

.nav-body-details-links
    display: flex
    flex-direction: column
    a
        line-height: 1
        color: #fff
        font-size: 22px
        font-weight: 500
        margin: 0 0 20px
        letter-spacing: 1px
        text-decoration: none
        text-transform: uppercase
        font-family: 'Vollkorn', serif
        background: linear-gradient(to right, #c69c6d, #c69c6d 50%, #fff 50%)
        background-clip: text
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-size: 200% 100%
        background-position: 100%
        transition: background-position 0.4s ease
        @media only screen and (max-width: $max-large)
            font-size: 18px
    a:hover
        background-position: 0 100%
    a:nth-last-child(1)
        margin: 0 0 10px

.nav-body-details-right
    display: flex
    align-items: center
    @media only screen and (max-width: $max-large)
        margin: 24px 0 0
        .vertical-line
            margin: 0 24px 0 0

.nav-body-details-info-item
    margin: 0 0 14px
    font-family: 'Titillium Web', sans-serif
    .nav-body-details-info-item-title
        color: $primary
        font-size: 12px
        text-transform: uppercase
    .nav-body-details-info-item-text
        font-size: 14px
