@import 'src/assets/variables'

.home-description
    min-height: 100vh
    display: flex
    padding: 32px 0
    align-items: center
    flex-direction: column
    justify-content: center
    background-color: #EFEFEF
    background-size: 100vw 50px
    @media only screen and (max-width: $max-desktop)
        min-height: 80vh
        padding: 32px 0 12px
    .home-description-title
        display: inline-block
        font-size: 54px
        font-weight: 500
        margin: 24px 0 0
        text-align: center
        font-family: 'Vollkorn', serif
        span
            margin: 0 auto
            color: $primary
            border-bottom: 2px solid $primary
        @media only screen and (max-width: $max-large)
            font-size: 32px
    .dots
        margin: 36px 0
        @media only screen and (max-width: $max-large)
            margin: 28px
    .home-description-desc
        text-align: left
        padding: 0 35%
        @media only screen and (max-width: 1800px)
            padding: 0 30%
        @media only screen and (max-width: $max-desktop)
            padding: 0 15%
        @media only screen and (max-width: $max-medium)
            padding: 0 24px
            font-size: 15px
    .home-description-buttons
        width: 25%
        display: flex
        margin: 42px 0 0
        flex-direction: row
        justify-content: space-between
        @media only screen and (max-width: 1800px)
            width: 40%
        @media only screen and (max-width: $max-desktop)
            width: 50%
        @media only screen and (max-width: $max-medium)
            width: 90%
            margin: 42px 0 24px
        @media only screen and (max-width: 350px)
            width: 100%
            overflow: hidden
            button
                margin: 0 5px 0
