@import 'src/assets/variables'

.contact-map
    width: 70%
    margin: 3vh 0 0
    height: 35vh
    .map-horizontal-line
        width: 400px
        margin: 0px 0 10px
        border-bottom: 2px solid $primary
    .contact-map-marker
        width: 40px
        height: auto
        position: absolute
        transform: translate(-50%, -50%)
        img
            width: 100%
    @media only screen and (max-width: $max-desktop)
        width: 80%
    @media only screen and (max-width: $max-desktop) and (orientation: landscape)
        height: 40vh
    @media only screen and (max-width: $max-medium)
        width: 100%
