@import 'src/assets/variables'

.gallery__container
    width: 100%
    display: flex
    padding: 12vh 0 0
    flex-direction: column
    align-items: center
    @media only screen and (max-width: 1250px) and (orientation: landscape)
        padding: 10vw 0 0
    .gallery__title
        margin: 32px 0
        font-size: 56px
        font-family: 'Vollkorn', sans-serif
    .gallery__content
        width: 55%
        display: flex
        flex-direction: column
        justify-content: flex-start
        @media only screen and (max-width: 1500px)
            width: 80%
        @media only screen and (max-width: $max-medium)
            width: 95%
    .gallery__wide
        width: 25vw
        @media only screen and (max-width: $max-medium)
            width: 20vw
        .horizontal-line-actual-line
            width: 100%
    .gallery__pictures
        width: 100%
        margin: 2px 0
        .gallery__pictures_row
            display: flex
            flex-direction: row
            justify-content: space-between
            flex-wrap: wrap
            @media only screen and (max-width: $max-medium)
                flex-direction: column
                justify-content: center
            .gallery__picture
                width: 30%
                margin: 6px 0
                height: auto
                align-self: flex-start
                @media only screen and (max-width: $max-medium)
                    width: 95%
                    margin: 6px auto
    .gallery__picture_active
        width: 100%
        height: 100%
        overflow: auto
        display: inline-block
        position: fixed
        z-index: 20
        top: 0
        text-align: center
        background-color: rgba(0, 0, 0, 0.8)
        img
            margin: 20vh 0 0
            max-width: 40vw
            max-height: 60vh
            @media only screen and (max-width: $max-desktop)
                max-width: 90vw
                max-height: 90vh
    .gallery__picture_active_close
        position: absolute
        top: 15px
        right: 35px
        color: #f1f1f1
        font-size: 40px
        font-weight: bold
        transition: 0.3s

    .gallery__picture_active_close:hover,
    .gallery__picture_active_close:focus
        color: #bbb
        text-decoration: none
        cursor: pointer
    .gallery__buttons
        display: flex
        justify-content: center
        button
            margin: 6px 12px
